import { ActionProps, ActionPropsArray, PageAction } from '@/components/actions';
import PageWrapper from '@/components/page/wrapper';
import PreviewDrawerSkeleton from '@/components/skeletons/previewDrawerSkeleton';
import { useGraphQL } from '@/data';
import { EstimatePublicRead } from '@/data/commerce/estimate.graphql';
import { InvoicePublicRead } from '@/data/commerce/invoice.graphql';
import { OrderPublicRead } from '@/data/commerce/order.graphql';
import { Order, OrderPublic } from '@/generated/graphql';
import { isEstimateType, isInvoiceType } from '@/helpers/useIsEstimateType';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useModalControls } from '@/providers/modal';
import { CommerceType, QueryEstimateReadArgs, QueryInvoiceReadArgs, QueryOrderReadArgs } from '@/types/schema';
import { getBrowserTimezone } from '@/utils/timezone';
import { CloseRounded as CloseRoundedIcon } from '@mui/icons-material';
import { Box, IconButton, Paper } from '@mui/material';
import { startCase, toLower } from 'lodash-es';
import ClientView from '../../../p/commerce/invoiceEstimate/clientView';
import OrderView from '../../../p/commerce/order/order';
import useEstimateActions from '../estimates/actions/tableActions';
import useInvoiceActions from '../invoices/actions/tableActions';
import useOrderActions from '../orders/actions/tableActions';

export default function CommerceTablePreviewDrawer( {
	dataId,
	type,
	hideActions,
}: {
	dataId: string,
	type: CommerceType,
	hideActions?: boolean
} ) {
	const timezone = getBrowserTimezone();
	const { staff } = useUserInfo();
	const { closeModal } = useModalControls();
	const {
		data      : invoiceData,
		isFetching: invoiceIsFetching,
	} = useGraphQL<QueryInvoiceReadArgs>( {
		query       : InvoicePublicRead,
		subscription: { INVOICE: staff?.company?.id as string },
		queryKey    : [ 'invoicePublicRead' ],
		variables   : { id: dataId },
	}, { enabled: Boolean( dataId ) && isInvoiceType( type ) } );
	
	const {
		data      : estimateData,
		isFetching: estimateIsFetching,
	} = useGraphQL<QueryEstimateReadArgs>( {
		query       : EstimatePublicRead,
		subscription: { ESTIMATE: staff?.company?.id as string },
		queryKey    : [ 'estimatePublicRead' ],
		variables   : { id: dataId },
	}, { enabled: Boolean( dataId ) && isEstimateType( type ) } );
	
	const { data: orderData, isFetching: orderIsFetching } = useGraphQL<QueryOrderReadArgs>( {
		query       : OrderPublicRead,
		subscription: { ORDER: staff?.company?.id as string },
		queryKey    : [ 'orderPublicRead' ],
		variables   : { id: dataId },
	}, { enabled: Boolean( dataId ) && type === 'ORDER' } );
	const data = invoiceData?.invoicePublicRead || estimateData?.estimatePublicRead || orderData?.orderPublicRead;
	
	const dataColor = data?.metadata?.documentLayout?.color || data?.company.metadata?.documentLayout?.color;
	const dataTemplate = data?.metadata?.documentLayout?.template || data?.company.metadata?.documentLayout?.template;
	const lineItemLayout = data?.metadata?.documentLayout?.lineItemLayout || data?.company.metadata?.documentLayout?.lineItemLayout;
	
	const selectionsMenu = staff?.company.metadata?.selectionsMenu || [];
	
	return (
		<PageWrapper
			hideBack
			modalWrapper
			animateLayoutChange
			loading={invoiceIsFetching || estimateIsFetching || orderIsFetching}
			loadingComponent={( <PreviewDrawerSkeleton width={type === 'ORDER' ? 510 : 700}/> )}
			boxProps={{
				sx: {
					bgcolor: 'background.default',
					height : '100%',
					width  : { md: type === 'ORDER' ? 510 : 700 },
				},
			}}
			primary={`${startCase( toLower( data?.type ) )} #${data?.metadata?.customNumber || data?.number}`}
			secondary={data?.type !== 'ORDER' && data?.client
				? data?.client?.name || data?.client?.email || data?.client?.contact
				: ''}
			actions={(
				<IconButton
					sx={{ mb: 2 }}
					size='small'
					onClick={closeModal}>
					<CloseRoundedIcon/>
				</IconButton>
			)}>
			{data && (
				<Box>
					{hideActions ? null : !data.deletedAt && (
						<PageAction<OrderPublic>
							single
							item={data}
							max={6}
							useActions={( isInvoiceType( type )
								? useInvoiceActions
								: isEstimateType( type )
									? useEstimateActions
									: useOrderActions ) as () => ActionPropsArray}
						/>
					)}
					{type === 'ORDER' ? (
						<Box sx={{ mt: 1 }}>
							<OrderView timezone={timezone} order={data as any}/>
						</Box>
					) : (
						<Paper
							key='View'
							variant='elevated'
							sx={{
								minHeight      : 842,
								transformOrigin: 'center',
								maxWidth       : 680,
								mt             : 5,
								bgcolor        : ( theme ) => theme.palette.mode === 'dark'
									? 'background.paper'
									: 'background.default',
							}}>
							<ClientView
								staff={staff}
								timezone={timezone}
								invoice={data as any}
								layoutColor={dataColor}
								layout={dataTemplate}
								lineItemLayout={lineItemLayout}
								selectionsMenu={selectionsMenu}
							/>
						</Paper>
					)}
				</Box>
			)}
		</PageWrapper>
	);
	
}
